<template>
  <div id="training" v-if="isInteractionsLoaded">
    <b-row v-if="!all && !isWellcome">
      <b-col
        lg="4"
        md="6"
        sm="12"
        class="mb-8 d-flex justify-content-center"
        v-for="mod in activeModules"
        :key="mod.id"
      >
        <vue-card-stack
          :cards="mod.interactions"
          :stack-width="380"
          :card-width="320"
          :maxVisibleCards="
            getInteractionsByColor(mod).length > 10
              ? 10
              : getInteractionsByColor(mod).length + 1
          "
        >
          <template v-slot:card="{ card }">
            <stacked-card
              :card="card"
              :type="mod"
              :allBtn="true"
              @seeAll="seeAll"
            />
          </template>
          <template
            v-slot:nav="{ onNext, onPrevious }"
            v-if="getInteractionsByColor(mod).length > 1"
          >
            <nav class="d-flex justify-content-center">
              <b-btn
                v-on:click="onPrevious"
                variant="link-dark"
                class="btn-hover-light-dark btn-floating"
              >
                <i
                  class="icon-2x text-dark-25 flaticon2-back"
                  style="color: #D1D3E0 !important;"
                ></i>
              </b-btn>
              <b-btn
                v-on:click="onNext"
                variant="link-dark"
                class="btn-hover-light-dark btn-floating"
              >
                <i
                  class="icon-2x text-dark-25 flaticon2-next"
                  style="color: #D1D3E0 !important;"
                ></i>
              </b-btn>
            </nav>
          </template>
        </vue-card-stack>
      </b-col>
    </b-row>
    <b-row v-else-if="isWellcome">
      <b-col cols="12">
        <div class="d-flex flex-column text-dark-65">
          <span class="svg-icon svg-icon-10x">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M16 15.632V12a3 3 0 00-3-3H6.162V5.526A2.526 2.526 0 018.688 3h11.79a2.526 2.526 0 012.526 2.526v7.58l.017 4.68a.5.5 0 01-.854.356l-2.51-2.51H16z" fill="#000"/><path d="M1.985 18v-5a2 2 0 012-2h8a2 2 0 012 2v5a2 2 0 01-2 2H4.101l-1.244 1.19a.5.5 0 01-.846-.36v-2.506A2.014 2.014 0 011.985 18zM6.5 14a.5.5 0 100 1h5a.5.5 0 100-1h-5zm3 2a.5.5 0 100 1h2a.5.5 0 100-1h-2z" fill="#000" opacity=".3"/></g></svg>
          </span>
          <span class="h2">
            {{ $t("training.isWellcome.title") }}
          </span>
          <span class="font-size-lg">
            {{ $t("training.isWellcome.description") }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row v-else-if="all">
      <b-col cols="12" class="text-left">
        <b-btn
          @click="all = false"
          variant="secondary"
          pill
          class="mb-8 font-weight-bolder"
        >
          <div class="d-flex align-items-center">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-btn>
      </b-col>
      <b-col
        lg="3"
        md="6"
        class="mb-8"
        v-for="card in getInteractionsByColor(all)"
        :key="card.id"
      >
        <card
          :card="card"
          :link="card.link"
          style="min-height: 100%;"
          :date="card.dateStatus"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import StackedCard from "@/components/training/StackedCard.component";
import Card from "@/components/profile/Card.component";
import VueCardStack from "vue-card-stack";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Training",
  components: {
    StackedCard,
    VueCardStack,
    Card
  },
  data() {
    return {
      all: false
    };
  },
  computed: {
    ...mapGetters("Interactions", ["isInteractionsLoaded", "sessions"]),
    ...mapGetters("Profile", ["modules"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    activeModules() {
      let result = [];

      _.each(this.modules, mod => {
        let interactions = this.getInteractionsByColor(mod);
        if (interactions.length > 0) {
          interactions = _.concat(interactions, interactions);
          while (interactions.length < 3)
            interactions = _.concat(interactions, interactions);
          mod.interactions = interactions;
          result = _.concat(result, mod);
        }
      });

      return result;
    },
    isWellcome() {
      if (this.sessions.length < 2) {
        var session = _.last(this.sessions);
        if (
          session &&
          parseInt(this.getConstant("SURVEYTYPE_KYMATIO_WELCOME")) ===
            parseInt(session ? session.typeId : -1)
        )
          return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions("Interactions", ["loadInteractions"]),
    getInteractionsByColor(mod) {
      return _.filter(
        this.sessions,
        session =>
          session.color === mod.color &&
          parseInt(session.surveyTypeId) !==
            parseInt(this.getConstant("SURVEY_KYMATIO_WELCOME"))
      );
    },
    seeAll(mod) {
      this.all = mod;
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isInteractionsLoaded) {
      this.loadInteractions();
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadInteractions();
      }
    },
    isInteractionsLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.loadInteractions();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: center;
}

.btn-floating {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  &:last-of-type {
    right: -5%;
  }
  &:first-of-type {
    left: -5%;
  }
}
</style>
