<template>
  <b-card
    no-body
    :class="`cardround ${allBtn ? 'personal-card' : 'small-card'}`"
  >
    <div class="d-flex flex-column px-6 card-body">
      <div class="d-flex justify-content-center">
        <div :class="`${card.color} top-icon`">
          <i :class="`fa tcicon ${card.icon}`" v-if="card.icon"></i>
          <span
            :class="`svg-icon svg-icon-4x svg-icon-white`"
            v-if="card.image"
          >
            <simple-svg :src="require(`@/assets/images/icons/${card.image}`)" />
          </span>
        </div>
      </div>
      <div class="mt-4 h3 font-weight-bolder" v-html="type.title" />
      <div class="mt-2 h5 font-weight-bolder" v-html="card.title" />
      <div class="text-dark-65 font-weight-bold pt-4" v-if="card.description">
        {{ card.description }}
      </div>
      <div class="text-dark-50 font-weight-bold pt-4" v-else>
        {{ type.shortDesc }}
      </div>
      <div class="text-dark-65 mt-4 font-weight-boldest" v-if="card.date">
        {{ date }}
      </div>
      <div class="mt-4 w-100 text-left font-weight-bold">
        <span class="text-dark-50">{{ $t(`tables.status.header`) }}:</span>
        <span
          :class="
            `label label-lg label-outline-${getStatusClass} font-weight-bolder label-inline ml-2`
          "
        >
          {{ $t(`dashboard.sessions.status.${card.status}`) }}
        </span>
      </div>
      <div class="mt-6 text-center d-flex d-flex-row justify-content-center">
        <b-button
          :variant="buttonColor"
          pill
          size="sm"
          :class="`font-weight-bolder`"
          @click="goToCardDetail"
        >
          <i :class="buttonIcon"></i
          >{{ $t(`generic.buttons.sessions.${card.status}`) }}
        </b-button>
        <b-button
          :variant="'light'"
          size="sm"
          pill
          class="font-weight-bolder ml-4"
          @click="$emit('seeAll', type)"
          v-if="allBtn"
        >
          <i class="flaticon2-add" />
          {{ $t("dashboard.sessions.all") }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { SimpleSVG } from "vue-simple-svg";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "StackedCard",
  props: {
    card: {
      type: Object,
      required: true
    },
    type: {
      type: Object,
      required: true
    },
    allBtn: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    "simple-svg": SimpleSVG
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("Profile", ["interactions"]),
    ...mapGetters("Chatbot", ["bots"]),
    date() {
      return moment(this.card.dateStatus).format("ll");
    },
    progressClass() {
      var result = "default";
      switch (_.toLower(this.card.surveyStatus)) {
        case "finish":
          result = "success";
          break;
        case "available":
          result = "warning";
          break;
        case "progress":
          result = "info";
          break;
        case "unavailable":
          result = "default";
          break;
      }
      return result;
    },
    buttonColor() {
      var variant = "secondary";
      if (this.card) {
        if (_.toLower(this.card.surveyStatus) === "finish") {
          variant = "primary";
        } else if (_.toLower(this.card.surveyStatus) === "progress") {
          variant = "warning";
        } else if (_.toLower(this.card.surveyStatus) === "available") {
          variant = "dark";
        } else if (_.toLower(this.card.surveyStatus) === "unavailable") {
          variant = "blocked";
        }
      }
      return variant;
    },
    buttonIcon() {
      var result = "flaticon2-next";
      if (this.card) {
        switch (_.toLower(this.card.surveyStatus)) {
          case "finish":
            result = "flaticon-eye";
            break;
          case "available":
            result = "flaticon2-next";
            break;
          case "progress":
            result = "flaticon2-fast-next";
            break;
          case "unavailable":
            result = "flaticon-clock-1";
            break;
        }
      }
      return result;
    },
    getStatusClass() {
      var result = "default";
      switch (_.toLower(this.card.surveyStatus)) {
        case "finish":
          result = "success";
          break;
        case "available":
          result = "warning";
          break;
        case "progress":
          result = "info";
          break;
        case "unavailable":
          result = "default";
          break;
      }
      return result;
    }
  },
  methods: {
    ...mapActions("Chatbot", ["activate"]),
    goToCardDetail() {
      switch (_.toLower(this.card.surveyStatus)) {
        case "unavailable":
          if (moment().isBefore(this.card.startDate)) {
            this.$swal(
              this.$t("training.isBlocked.title"),
              this.$t("training.isBlocked.description", {
                date: moment(this.card.startDate).fromNow(true)
              }),
              "warning"
            );
          }
          break;
        case "finish":
          if (this.$route.name !== "TrainingDetail") {
            this.$router.push({
              name: "TrainingDetail",
              params: { id: this.card.surveyEntityId }
            });
          }
          break;
        case "progress":
        case "available":
          this.activate(this.card.surveyEntityId);
          this.$root.$emit("bv::show::modal", "chatbot");
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.personal-card {
  width: 100%;
  height: 100%;
}
.small-card {
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}
.top-icon {
  border-radius: 10px;
  padding: 0.3rem;
}
</style>
