<template>
  <!--begin::Iconbox-->
  <b-card
    no-body
    class="card-custom mb-8 mb-lg-0 cardround card-strech gutter-b ove"
    @click="goToCardDetail"
  >
    <div :class="`topcard d-flex justify-content-center ${card.color}-opacity`">
      <div :class="`${card.color} top-icon`">
        <i :class="`fa tcicon ${card.icon}`" v-if="card.icon"></i>
        <span :class="`svg-icon svg-icon-4x svg-icon-white`" v-if="card.image">
          <simple-svg :src="require(`@/assets/images/icons/${card.image}`)" />
        </span>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex align-items-center p-3">
        <div class="d-flex flex-column" style="width: 100%;">
          <span>
            <router-link
              v-if="titleLink"
              class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3 pb-4"
              :to="{ name: titleLink }"
              v-html="card.title"
            ></router-link>
            <span
              v-else-if="link && card.status"
              style="cursor:pointer;"
              @click="goToCardDetail"
              class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3 pb-4"
              v-html="card.title"
            ></span>
            <router-link
              v-else-if="link && card.status"
              class="text-dark text-hover-primary font-weight-bold font-size-h4 mb-3 pb-4"
              :to="card.to"
              v-html="card.title"
            ></router-link>
            <span
              v-else
              class="text-dark font-weight-bold font-size-h4 mb-3 pb-4"
              v-html="card.title"
            ></span>
          </span>
          <div class="text-dark-50" v-if="date">
            {{ moment(card.dateStatus).format("ll") }}
          </div>
          <div class="text-dark-75 pt-6">
            {{ description }}
          </div>

          <div class="mt4 text-center" v-if="card.status">
            <div class="font-weight-bolder pb-1">
              <span
                :class="
                  `label label-lg label-outline-${statusClass} font-weight-bolder label-inline ml-2`
                "
              >
                {{ $t(`dashboard.sessions.status.${card.status}`) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex justify-content-center border-0 pt-0">
      <div class="mt6 text-center" v-if="hasSeeMoreButton">
        <b-button
          tag="a"
          variant="light"
          pill
          class="btn-sm mr-3"
          @click="showMore = !showMore"
        >
          {{ $t("generic.buttons.seeMore") }}
        </b-button>
      </div>
      <div class="mt10 text-center" v-if="card.status && link">
        <b-button
          tag="a"
          :variant="buttonColor"
          pill
          class="btn btn-default btn-sm mr-3"
          @click="goToCardDetail"
        >
          <i :class="buttonIcon"></i
          >{{ $t(`generic.buttons.sessions.${card.status}`) }}
        </b-button>
      </div>
      <div class="mt10 text-center" v-else-if="card.to && link">
        <b-button
          tag="a"
          pill
          variant="primary"
          class="btn-sm mr-3"
          @click="$router.push(card.to)"
        >
          <i class="mr-2 flaticon-eye"></i>
          {{ $t("generic.buttons.done") }}
        </b-button>
      </div>
    </div>
  </b-card>
  <!--end::Iconbox-->
</template>

<script>
import { SimpleSVG } from "vue-simple-svg";

import moment from "moment-timezone";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Card",
  components: {
    "simple-svg": SimpleSVG
  },
  data() {
    return {
      isHovering: false,
      showMore: false
    };
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    link: {
      type: Boolean,
      required: false,
      default: true
    },
    titleLink: {
      type: String,
      required: false
    },
    date: {
      type: String,
      required: false
    },
    seeMore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("Profile", ["interactions"]),
    buttonIcon() {
      var result = "flaticon2-next";
      if (this.card) {
        switch (this.card.status) {
          case "finish":
            result = "flaticon-eye";
            break;
          case "available":
            result = "warning";
            break;
          case "progress":
            result = "flaticon2-fast-next";
            break;
          case "unavailable":
            result = "flaticon-clock-1";
            break;
        }
      }
      return result;
    },
    description() {
      return this.seeMore && !this.showMore
        ? _.truncate(this.card.description, {
            length: 60,
            separator: "",
            ommision: "..."
          })
        : this.card.description;
    },
    hasSeeMoreButton() {
      return (
        this.seeMore &&
        !this.showMore &&
        this.description.length < this.card.description.length
      );
    },
    progressClass() {
      var result = "default";
      switch (this.card.status) {
        case "finish":
          result = "success";
          break;
        case "available":
          result = "warning";
          break;
        case "progress":
          result = "info";
          break;
        case "unavailable":
          result = "default";
          break;
      }
      return result;
    },
    buttonColor() {
      var variant = "secondary";
      if (this.card) {
        if (this.card.status === "finish") {
          variant = "light-primary";
        } else if (this.card.status === "progress") {
          variant = "light-warning";
        } else if (this.card.status === "available") {
          variant = "light-dark";
        } else if (this.card.status === "unavailable") {
          variant = "light-blocked";
        }
      }
      return variant;
    },
    statusClass() {
      var result = "default";
      switch (this.card.status) {
        case "finish":
          result = "success";
          break;
        case "available":
          result = "warning";
          break;
        case "progress":
          result = "info";
          break;
        case "unavailable":
          result = "default";
          break;
      }
      return result;
    }
  },
  methods: {
    ...mapActions("Chatbot", ["activate"]),
    moment(value) {
      return moment(value);
    },
    cardClick() {
      if (this.card.status && this.link) {
        this.goToCardDetail();
      } else if (this.card.to && this.link) {
        this.$router.push(this.card.to);
      }
    },
    goToCardDetail() {
      switch (this.card.status) {
        case "unavailable":
          if (moment().isBefore(this.card.startDate)) {
            this.$swal(
              this.$t("training.isBlocked.title"),
              this.$t("training.isBlocked.description", {
                date: moment(this.card.startDate).fromNow(true)
              }),
              "warning"
            );
          }
          break;
        case "finish":
          if (this.$route.name !== "TrainingDetail") {
            this.$router.push({
              name: "TrainingDetail",
              params: { id: this.card.surveyEntityId }
            });
          }
          break;
        case "progress":
        case "available":
          this.activate(this.card.surveyEntityId);
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ove {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}
.ove:hover {
  top: -5px;
  cursor: pointer;
}
.topcard {
  height: auto;
  padding: 2rem;
  .tcicon {
    font-size: 4rem;
  }
}
.top-icon {
  border-radius: 10px;
  padding: 0.3rem;
}
</style>
